@mixin animationStep($name) {
	&.anim-#{$name}, &.anim-#{$name}-complete {
		@content;
	}
}

@mixin setAnimationDuration($duration) {
	&, & * {
		transition-duration: 1s;
	}
}

@mixin supportsFancyMouse {
	@supports (--var: 1px) and (clip-path: none) {
		@content;
	}
}
