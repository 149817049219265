.splash {
	&-select {
		.splash-action.active {
			flex-basis: 5%;
			&:before {
				transform: scale(1.1, 1.1);
				filter: grayscale(0%) contrast(50%);
			}
			&:after {
				opacity: 0.9;
			}
		}
		@include animationStep(expand) {
			@include setAnimationDuration(0.5s);
			.splash-action {
				&:not(.active) {
					.splash-action-body {
						opacity: 0;
					}
				}
				&.active {
					flex-basis: 100%;
				}
				&.splash-left.active {
					.splash-action-body {
						left: 50%;
					}
				}
				&.splash-right.active {
					.splash-action-body {
						right: 50%;
					}
				}
			}
		}
		@include animationStep(reveal-message) {
			@include setAnimationDuration(0.3s);
			.splash-action {
				&-message {
					height: 5em;
				}
			}
		}
	}
}

@keyframes expand-divider {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

@keyframes expand-select {
	from {
		transform: translate(-50%, -50%) scale(0, 0);
	}
	to {
		transform: translate(-50%, -50%) scale(1, 1);
	}
}
