
.splash {
	overflow: hidden;

	&-logo {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
		perspective: 1000px;
		pointer-events: none;
		padding: 1em 1.5em;

		&:before {
			content: '';
			background: $white;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: -8em;
			transform-origin: top center;
			transform-style: preserve-3d;
			transform: rotateX(-65deg);
			z-index: 1;
			pointer-events: auto;
		}

		img {
			position: relative;
			z-index: 2;
			width: 140px;
		}

		@media #{$mq-largeScreen} {
			padding: 1em 3.5em;

			&:before {
				bottom: -16em;
			}

			img {
				width: 250px;
				height: 100%;
			}
		}
	}

	&-skip-message {
		position: absolute;
		font-size: 1.5em;
		left: 0.5em;
		bottom: 4em;
		z-index: 80;
		color: $white;
		opacity: 0;
		transition: opacity 200ms;

		&.show {
			opacity: 1;
		}
	}

	&-select {
		position: relative;
		display: flex;
		height: 100vh;
		align-items: stretch;
		flex-direction: column;

		&:after {
			content: '';
			background: $white;
			position: absolute;
			top: 68%;
			left: 50%;
			transform: translate(-50%, -68%);
			z-index: 5;
		}

		&:before {
			content: '';
			background: $white;
			position: absolute;
			top: 31.5%;
			left: 50%;
			transform: translate(-50%, -31.5%);
			z-index: 5;
		}
		/*&:before {
			animation: expand-divider 0.5s linear forwards 1;
			height: 1px;
			width: 100%;
		}*/

		&:after, &:before {
			/*animation: expand-select 0.5s 0.5s cubic-bezier(.73, .7, .63, 1.29) forwards 1;*/
			content: 'Select One';
			border-radius: 50%;
			font-size: 1.5em;
			width: 100px;
			height: 100px;
			padding: 0.5em;
			line-height: 1;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: $font-2;
			text-transform: uppercase;
			color: $color-blue;
			/*transform: scale(0);*/
			transform-origin: center center;
		}

		@media #{$mq-largeScreen} {
			flex-direction: row;

			&:before, &:after {
				display: none;
			}
		}
	}

	&-action {
		position: relative;
		display: block;
		height: 100%;
		flex: 1 1 0px;
		overflow: hidden;
		transition: flex-basis 0.3s;

		@media #{$mq-largeScreen} {
			/*cursor: none;*/
		}

		&:before, &:after {
			content: '';
			position: absolute;
		}

		&:before {
			opacity: 1;
			filter: grayscale(100%) contrast(20%);
			background-color: $white;
			background-size: cover;
			background-position: center center;
			transition: transform 0.3s, filter 0.3s;
			top: 0;
			height: 100%;
			width: 100vw;

			@media #{$mq-largeScreen} {
				width: 100vw;
				background-size: auto, 70vw;
			}
		}

		&:after {
			transition: opacity 0.3s;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0.9;

			@supports (mix-blend-mode: normal) {
				opacity: 1;
				mix-blend-mode: overlay;
			}
		}

		&-body {
			position: relative;
			z-index: 2;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;

			@media #{$mq-largeScreen} {
				width: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&-logos {
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

		&-image {
			//flex: 1 0 0px;
			img {
				padding: 1em;
				width: 100%;
				height: auto;

				@supports (object-fit: cover) {
					max-height: 7.5em;
					object-fit: contain;
				}
			}

			@media #{$mq-largeScreen} {
				img {
					@supports (object-fit: cover) {
						max-height: 20em;
					}
				}
			}
		}

		&-message {
			height: 0;
			overflow: hidden;
			transition: height 1s;

			h2 {
				color: $white;
				text-align: center;
			}
		}
	}

	&-left {
		padding-top: 2em;
		background: $color-red;

		&:before {
			left: 0;
			background-image: linear-gradient(to top, transparent 60%, #C10001 80%), url("/default/dist/images/theatres_splash-small.jpg");
			/*background-size: 120% auto;
			background-position: 50% -20%;*/
		}

		&:after {
			background-color: $color-red;
		}

		.splash-action-body {
			left: 0;
		}

		@media #{$mq-largeScreen} {
			padding-top: 0;
			cursor: url('/default/dist/images/cursor1.svg'), auto;

			&:before {
				background-image: linear-gradient(to right, transparent 100%, #C10001 65%), url("/default/dist/images/theatres_splash-large.jpg");
				background-size: inherit;
				background-position: -60% center;
			}
		}
	}

	&-right {
		background: $color-teal;
		padding-bottom: 2em;

		&:before {
			right: 0;
			background-image: url('/default/dist/images/hotels_splash-small.jpg');
			/*background-position: 100% 0;*/
		}

		&:after {
			background-color: $color-teal;
		}

		.splash-action-body {
			right: 0;
		}

		@media #{$mq-largeScreen} {
			padding-bottom: 0;
			cursor: url('/default/dist/images/cursor3.svg'), auto;

			&:before {
				background-image: linear-gradient(to left, transparent 100%, #3A8896 65%), url("/default/dist/images/hotels_splash-large.jpg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 260% auto;
				width: 100%;
			}
		}
	}

	&-cursor {
		&-mask {
			position: absolute;
			pointer-events: none;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 2;
			background: $color-red;
			border-radius: 50%;
			overflow: hidden;

			@include supportsFancyMouse {
				--left-width: 0px;
				--clip-x: 0px;
				--clip-y: 0px;
				--clip-radius: 0px;
				--cursor-size: calc(var(--clip-radius) * 2);
				display: flex;
				opacity: 0.9;
				background: #14516c;
				border-radius: 0;
				clip-path: circle(var(--clip-radius, 0px) at var(--clip-x) var(--clip-y));
				/*&:before, &:after {
					content: '';
					display: block;
				}*/
			}
			/*&:before {
				flex: 0 0 var(--left-width, 50%);
				background: $color-teal;
			}*/
			/*&:after {
				flex: 1 1 0px;
				background: $color-red;
			}*/
		}

		&-icon {
			position: absolute;
			display: block;
			left: 50%;
			top: 50%;
			width: 100%;
			height: 100%;
			transform: translate(-50%, -50%);

			@include supportsFancyMouse {
				top: var(--clip-y);
				left: var(--clip-x);
				width: var(--cursor-size, 0px);
				height: var(--cursor-size, 0px);
			}
		}
	}
}

.job-search {
	display: none !important;}

	.splash-center {
		padding-top: 2em;
		background: #333;
	}

	.splash-center:before {
		left: 0;
		background-image: linear-gradient(to top, transparent 60%, #333 80%), url("/default/dist/images/marcus-corporation-small.jpg");
		/*background-size: 120% auto;
    background-position: 50% -20%;*/
	}

	.splash-center:after {
		background-color: #333;
	}

	.splash-center .splash-action-body {
		left: 0;
	}

	@media only screen and (min-width: 1025px) {
		.splash-center {
			padding-top: 0;
			cursor: url('/default/dist/images/cursor2.svg'), auto;
		}

		.splash-center:before {
			background-image: linear-gradient(to left, transparent 100%, #333 80%), url("/default/dist/images/marcus-corporation-Large.jpg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 260% auto;
			width: 100%;
		}
	}
@media only screen and (min-width: 1679px) {
	.splash-left:before {
		background-image: linear-gradient(to top, transparent 100%, #C10001 80%), url(/default/dist/images/movie.jpg);
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 112% auto;
	}

	.splash-center:before {
		background-image: linear-gradient(to top, transparent 100%, #333 80%), url(/default/dist/images/corporate.jpg);
		width: 100%;
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: 112% auto;
	}

	.splash-center {
		padding-top: 0;
	}

	.splash-right:before {
		background-image: linear-gradient(to top, transparent 100%, #3A8896 80%), url(/default/dist/images/hotel.jpg);
		width: 100%;
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: 112% auto;
	}
}

@media only screen and (min-width: 1919px) {
	.splash-left:before {
		background-size: 100% auto;
	}

	.splash-center:before {
		background-size: 100% auto;
	}

	.splash-center {
		padding-top: 0;
	}

	.splash-right:before {
		background-size: 100% auto;
	}
}
	@media only screen and (max-width: 1919px) {
		.splash-left {
			padding-top: 0;
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: contain;
			width: 100%;
		}
	}

	@media only screen and (max-width: 1599px) {
		.splash-left {
			background-size: 80% auto;
		}

		.splash-center:before {
			background-size: 285% auto;
		}
	}

	@media only screen and (max-width: 1199px) {
		.splash-right {
			padding-bottom: 0;
		}

		.splash-action-image img {
			max-height: 15em;
			padding: 0;
		}

		.splash-action-body {
			justify-content: center;
			bottom: 0;
		}

		.splash-left, .splash-center, .splash-right {
			background-position: center bottom;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.splash-logo {
			padding: 5px 1.5em 0;
		}
		.splash-select {
			height: 300vh;
		}
	}

	@media only screen and (max-width: 991px) {

		.splash-select {
			height: 140vh;
		}
		.splash-left:before {
			left: 0;
			background-image: linear-gradient(to top, transparent 100%, #C10001 80%), url(/default/dist/images/theatres_splash-small.jpg);
		}

		.splash-center:before {
			background-image: linear-gradient(to top, transparent 100%, #333 80%), url(/default/dist/images/marcus-corporation-small.jpg);
			background-size: 100% auto;
		}
		.splash-select:before {
			top: 31%;
			transform: translate(-50%, -31%);
		}
		.splash-select:after {
			top: 68.5%;
			transform: translate(-50%, -68.5%);
		}
				}
@media only screen and (max-width: 567px) {
	.splash-select:before {
		top: 31.5%;
		transform: translate(-50%, -31.5%);
	}
	.splash-select:after {
		top: 68%;
		transform: translate(-50%, -68%);
	}

	.splash-center:before {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right 0;
	}

	.splash-action-body {
		justify-content: center;
		bottom: 0;
	}

	.splash-select {
		height: 150vh;
	}

	.splash-left:before {
		background-image: linear-gradient(to top, transparent 100%, #C10001 80%), url(/default/dist/images/movie-m.jpg);
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 105% auto;
	}

	.splash-center:before {
		background-image: linear-gradient(to top, transparent 100%, #333 80%), url(/default/dist/images/corporate-m.jpg);
		width: 100%;
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: 100% auto;
	}

	.splash-center {
		padding-top: 0;
	}

	.splash-right:before {
		background-image: url(/default/dist/images/hotel-m.jpg);
		width: 100%;
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: 100% auto;
	}
	.splash-action-image img {
		max-height: 8em;
	}
	
		}
/*Removed safe house image 25.4.2024*/
.splash-left .splash-action-image img {
	max-height: inherit;
	height: 190px;
}
.splash-right .splash-action-image img {
	max-height: inherit;
		height:175px;
}
@supports ((-o-object-fit: cover) or (object-fit: cover)) {
	.splash-action-image img {
		max-height: inherit;
		height: 175px;
	}
}
@media only screen and (max-width: 767px) {
	.splash-left .splash-action-image img {
		height: 120px;
	}

	.splash-right .splash-action-image img {
		height: 120px;
	}

	@supports ((-o-object-fit: cover) or (object-fit: cover)) {
		.splash-action-image img {
			height: 120px;
		}
	}

	.splash-select:before {
		top: 31.5%;
	}
}



