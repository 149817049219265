.job-search {
	/*display: none;*/
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 10;
	border-top: 1px solid $white;
	@media #{$mq-largeScreen} {
		background: $color-blue;
	}
	.button { // Applies to mobile button and desktop submit
		@extend .button-1;
	}
	&-button {
		.button {
			display: block;
			margin: 0;
			width: 100%;
			max-width: none;
		}
		@media #{$mq-largeScreen} {
			display: none;
		}
	}
	&-container {
		@extend %container;
		display: none;
		@media #{$mq-largeScreen} {
			display: block;
		}
	}
	&-form {
		display: flex;
		align-items: center;
		justify-content: space-between;
		h2 {
			color: $white;
			font-size: 2.625em;
			flex: 0 0 auto;
		}
		.button {
			margin: 0;
			padding: 1.0625em;
			min-width: 250px;
		}
		.form-fields {
			display: flex;
			flex: 1 1 auto;
			justify-content: space-around;
			justify-content: space-evenly;
			.form-group {
				flex: 0 0 45%;
				color: $white;
				margin-top: 1em;
				margin-bottom: 1em;
			}
			.form-label {
				background: $color-blue;
			}
			input, select {
				font-size: 1.25em;
			}
		}
	}
}
