.mobile-hide {
	display: none;
}

@media #{$mq-mediumScreen} {
	.mobile-only {
		display: none !important;
	}
	.mobile-hide {
		display: initial;
	}
}
