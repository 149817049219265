/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

*,
*:before,
*:after {
	box-sizing: border-box;
	flex: 0 1 auto;
}

html {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 1rem;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1.4;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

label {
	cursor: pointer;
}

@media print {
	* {
		background: transparent !important;
		color: black !important;
		box-shadow: none !important;
		text-shadow: none !important;
		filter: none !important;
		-ms-filter: none !important;
	}
	/* Black prints faster: h5bp.com/s */
	a, a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	.ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
		content: "";
	}
	/* Don't show links for images, or javascript/internal links */
	pre, blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}
	/* h5bp.com/t */
	tr, img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 0.5cm;
	}

	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}

	h2, h3 {
		page-break-after: avoid;
	}
}
