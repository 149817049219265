.button {
	// Base button styles
	display: block;
	background: #000000;
	color: #ffffff;
	font-family: $font-1;
	padding: 1.25em 1em;
	font-size: 1em;
	border: none;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.3375em;
	margin: 0 auto 0.75em;
	line-height: 1.2;
	cursor: pointer;
	max-width: 90%;
	text-decoration: none;
	text-align: center;
	width: 100%;
	&:hover, &:focus {
		color: $white;
	}
	@media #{$mq-mediumScreen} {
		margin: 10px 0.5em 0 0;
		display: inline-block;
		width: auto;
		min-width: 300px;
		max-width: none;
	}
	&-1 {
		// Should be colors only
		background: $color-red;
		&:hover, &:focus {
			background: darken($color-red, 10%);
		}
	}
	&-2 {
		// Should be colors only
		background: $color-blue;
		&:hover, &:focus {
			background: darken($color-blue, 10%);
		}
	}
}
