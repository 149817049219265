@mixin clearfix($pseudo: after) {
	&:#{$pseudo} {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		font-display: swap;
		src: $src;
	}
}

@mixin font-smoothing($value: antialiased) {
	@if $value == antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	} @else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

@mixin flex-wrap-column($columns: 4, $gutter: 1.5em, $vmargin: 2em) {
	// Warning: Math
	$flex-width: (100/$columns) + '%';
	$flex-spacing: (($columns - 1)/$columns) * $gutter;
	$margin-width: $gutter / 2;

	// Actual CSS Properties
	flex: 0 0 calc(#{$flex-width} - #{$flex-spacing});
	margin: $vmargin $margin-width 0;
	&:nth-child(#{$columns}n + 1) { // first column
		margin-left: 0;
	}
	&:nth-child(#{$columns}n) { // last column
		margin-right: 0;
	}
	&:nth-child(-n + #{$columns}) { // first row
		margin-top: $vmargin / 2;
	}
}
