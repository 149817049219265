.section {
	@extend %section !optional;
	&-hero {
		@media #{$mq-largeScreen} {
			margin-bottom: 0;
		}
	}
	&-header {
		@extend %container !optional;
		margin-bottom: 1em;
		@media #{$mq-mediumScreen} {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
		h2 {
			line-height: 1;
		}
	}

	&-title {
		&-centered {
			margin: auto;
		}
	}

	&-arrows {
		text-align: center;
		margin: #{$margin-mobile / 2} auto;
		@media #{$mq-mediumScreen} {
			text-align: left;
			margin: 0 0.5rem;
		}
		.slick-arrow {
			display: inline-flex;
			position: relative;
			left: auto;
			right: auto;
			width: auto;
			height: auto;
			color: $color-blue;
			top: auto;
			transform: none;
			margin: 0 0.5rem;
			&::before {
				display: none;
			}
			svg.svg-inline--fa {
				font-size: 2rem;
			}
		}
	}
	&-cta {
		width: 80%;
		margin: auto;
		@media #{$mq-mediumScreen} {
			margin-right: 0;
			width: auto;
		}
		.button {
			max-width: none;
			width: 100%;
			margin: 0;
			@media #{$mq-mediumScreen} {
				width: auto;
			}
		}
	}
}

.content {
	&-head {
		> .section:first-child {
			margin-top: 0;
		}
	}
	&-main {
		> .section:first-child {
			margin-top: 0;
		}
	}
}

.container {
	@extend %container !optional;
}

.divider {
	border: none;
	height: 2px;
	width: 100%;
	background: $color-teal;
}
