	html {
}

body {
	color: $color-text;
	font-size: 16px;
	font-family: $font-1;
}

h1, h2 {
	color: $color-red;
	font-family: $font-2;
	font-size: 2.875em;
	font-weight: normal;
	line-height: 0.95;
	text-transform: uppercase;
	@media #{$mq-largeScreen} {
		font-size: 4.375em;
		line-height: 1.2;
	}
}

h3 {
	color: $color-teal;
	font-size: 1.125em;
	line-height: 1.1;
	text-transform: uppercase;
	letter-spacing: 0.3375em;
}

h4, h5, h6 {
}

// Block-level text elements
p, ul, ol {
	line-height: 1.5;
	font-size: 1em;
	margin: 0.75em 0;
}

ul, ol {
	padding: 0;
	list-style-position: outside;
	margin-left: 1em;
}

img, video {
	display: block;
	width: 100%;
}

// Inline text elements
a, span, b, strong, em, i,
label, button, select, input, textarea {
}

a {
	font-weight: bold;
	color: $color-red;
	text-decoration: underline;
	&:hover, &:focus {
		color: $color-blue;
	}
}
