// region Colors
$color-red: #C10001 !default;
$color-blue: #13506C !default;
$color-teal: #3A8896 !default;
$color-text: #2A2D2E !default;
$white: #fff;
$gray: #ddd;
// endregion

// region Fonts
$font-1: 'Quattrocento Sans', sans-serif !default;
$font-2: 'Pathway Gothic One', sans-serif !default;
// endregion

// region Media Queries
$mq-smallScreen: "only screen and (min-width:480px)";
$mq-mediumScreen: "only screen and (min-width:768px)";
$mq-largeScreen: "only screen and (min-width:1025px)";
$mq-xLargeScreen: "only screen and (min-width:1200px)";
$mq-xxLargeScreen: "only screen and (min-width:1400px)";
// To Use... @media #{$mq-mediumScreen}{ }
// endregion

// region Frame Config
$frame-padding-mobile: 0.75em !default;
$frame-padding-desktop: 1em !default;
$frame-positions-vertical: top, bottom;
$frame-positions-horizontal: left, right;
$theme-colors: (
	red: $color-red,
	teal: $color-teal,
	blue: $color-blue
) !default;
// endregion

// region Other
$margin-mobile: 2rem;
$margin-desktop: 6rem;
// endregion
