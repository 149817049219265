@import "shared-abstracts";

@import 'vendor/reset';
@import "slick-carousel/slick/slick.scss";
@import "slick-carousel/slick/slick-theme.scss";

@import 'base/fonts';
@import 'base/defaults';
@import 'base/layout';
@import 'base/helpers';

@import 'components/buttons';
@import 'components/forms';

@import 'splash/splash';

@import 'modules/job-search';
