.contact-form {
	@extend %container;
}

.form-inline {
	display: block;
	@media #{$mq-largeScreen} {
		display: flex;
	}
	.form-group {
		flex: 1 1 auto;
		margin: 0;
		@media #{$mq-largeScreen} {
			margin-right: 1em;
		}
	}
	.button {
		flex: 0 0 20%;
		padding: 1em 1em;
		margin: 1em 0 0;
		max-width: none;
		@media #{$mq-largeScreen} {
			margin-top: 0;
		}
	}
}

.form-group {
	position: relative;
	margin: 1.5em 0;
	.parsley-errors-list {
		position: absolute;
		font-size: 10px;
		text-transform: uppercase;
		color: #f44545;
		margin-right: 5px;
		right: 0;
		top: 35px;
	}

	input[type], select, textarea {
		&.parsley-error {
			background-color: #feeeee;
			border: 1px solid #f44545 !important;
		}
	}

	select option {
		color: black;
	}

	&:after {
		display: block;
		content: '';
		clear: both;
	}

	@media #{$mq-largeScreen} {
		.parsley-errors-list {
			right: 15px;
			top: 10px;
		}
	}
	.fa-calendar-alt {
		position: absolute;
		right: 15px;
		color: inherit;
		top: 24%;
		font-size: 24px;
		pointer-events: none;

	}
	.form-label {
		position: absolute;
		top: -0.5em;
		left: 10px;
		color: inherit;
		background-color: $white;
		padding: 0px 5px;
		pointer-events: none;
	}
	.form-textarea {
		background-color: #fff;
		border: 1px solid $color-text;
		color: $color-text;
		height: 80px;
		padding: 15px;
		width: 100%;
		display: inline-block;
		@media #{$mq-mediumScreen}{
			height: 200px;
			margin: 10px 0;
			width: 79%;
		}
		@media #{$mq-largeScreen}{
			margin: 0px 0px 5px 0px;
			width: 90%;
		}
	}
	.file-attachment__note {
		display: block;
		font-style: italic;
	}
	input,
	select {
		height: 50px;
		border: 0.125em solid currentColor;
		color: inherit;
		background: transparent;
		padding: 0px 10px;
		max-width: none;
		width: 100%;
		font-size: 1.25em;
		&:disabled {
			color: $gray;
			border-color: $gray;
			cursor: not-allowed;
			~ svg {
				color: $gray;
			}
		}
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}
	input[type=number] {
		-moz-appearance: textfield;
	}
	&-select {
		&:after {
			content: '▼';
			color: inherit;
			position: absolute;
			top: 50%;
			right: 0.5em;
			transform: translateY(-50%);
		}
		select {
			-moz-appearance: none;
			-webkit-appearance: none;
		}
	}
	.recaptcha-border {
		border: 2px solid red;
		border-radius: 4px;
		display: inline-block;
	}

	.error-message {
		display: none;
		text-align: right;
		margin-right: 5%;

		p {
			color: red;
			font-size: 16px;
		}
	}
	.button-iterator {
		position: absolute;
		background: none;
		font-size: 24px;
		display: block;
		max-height: 25px;
		right: 8px;
		width: 0;
		height: 0;
		border-style: solid;
		padding: 0;
		border-radius: 3px;
		&.button-up {
			color: inherit;
			top: 10px;
			border-width: 0 8px 10px 8px;
			border-color: transparent transparent currentColor;
		}
		&.button-down {
			color: inherit;
			bottom: 10px;
			border-width: 10px 8px 0px 8px;
			border-color: currentColor transparent transparent;
		}
		svg {
			color: inherit;
		}
	}
}
.form-wrap {
	@extend %section;
	.columns {
		margin: 0;
	}
	.form-success {
		display: none;
	}
}
.rfp {
	&-content {
		&__block {
			padding: 15px 0px;
			margin: 0px 50px 0px 0px;
			&-section {
				display: flex;
				flex-direction: column;
				@media #{$mq-mediumScreen} {
					flex-direction: row;
					div {
						margin: 0px 30px 0px 0px;
					}
				}
			}
		}
	}
	&-form {
		&--section {
			border-top: 2px dashed $color-teal;
			padding: 50px 0px;
			display: flex;
			flex-direction: column;
			.rfp-content__block {
				flex-basis: 42%;
			}
			input {
				height: 50px;
				border: 1px solid $color-text;
				color: inherit;
				background: transparent;
				border-radius: 15px;
				padding: 0px 10px;
				max-width: none;
				width: 100%;
				font-size: 24px;
				margin: 20px 0px;
				&:disabled {
					color: $gray;
					border-color: $gray;
					cursor: not-allowed;
					~ svg {
						color: $gray;
					}
				}
				&[type=radio], &[type=checkbox] {
					display: inline-block;
					width: auto;
					vertical-align: middle;
					height: 1em;
					margin: 0 0.5em 0 0;
				}
			}
			.checkbox-field {
				margin-bottom: 1em;
			}
			.checkbox-row {
				margin: 1em 0;
				label {
					vertical-align: middle;
					margin: 0;
				}
			}
		}
	}
}

.form-label {
	font-weight: bold;
	color: inherit;
}
@media #{$mq-largeScreen}{
	.rfp {
		&-form {
			&--section {
				flex-direction: row;
			}
		}
	}
	.columns {
		display: flex;
		.c3x6 {
			flex-basis: 44%;
			margin: 0px 50px 0px 0px;
			&:nth-child(2) {
				flex-basis: 30%;
			}
		}
	}

}
