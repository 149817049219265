%section {
	margin: $margin-mobile 0;
	overflow: hidden;
	@media #{$mq-mediumScreen} {
		margin: $margin-desktop 0;
	}
}

%container {
	max-width: 1350px;
	margin: auto;
	padding: 0 20px;
}
